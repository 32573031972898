<template>
  <pt-task-order-detail class="pt-task-order-detail__page" :id="id"></pt-task-order-detail>
</template>

<script>
import PtTaskOrderDetail from 'cps//pt-task-order-detail/withData'
export default {
  name: 'pt-task-order-detail__view',
  components: {
    'pt-task-order-detail': PtTaskOrderDetail
  },
  props:{
    id:String
  }
}
</script>
