<template>
  <div :class="$style['status-operation']">
				<cube-checkbox v-model="allSelect" v-if="isDistributeTask">
					全选
				</cube-checkbox>
			<template v-else>
    <span @click="$refs.tip.show()">更多</span>
    <cube-tip ref="tip" direction="bottom" offsetLeft="30px" :class="$style.tip">
      <div :class="$style.otherBtn">
        <span @click="handleReassign">改派任务</span>
      </div>
      
    </cube-tip>
		</template>
    <div :class="$style.btns">
      
			<template v-if="!isDistributeTask">
        <!-- <template v-if="!hasTask"> -->
          <!-- 待领样 -->
          <template v-if="nextStatus === 'ORDER_GET'">
            <wt-button primary @click="taskStatusUpdate">确认已领样</wt-button>
          </template>
          <!-- 待检测 -->
          <template v-if="nextStatus === 'ORDER_EXAM'">
            <wt-button outline danger @click="getReceived">复测</wt-button>
            <wt-button primary @click="taskStatusUpdate">确认已提交原始记录</wt-button>
          </template>
          <!-- 待报告： -->
          <template v-if="nextStatus === 'REPORT_CREATE'">
            <wt-button primary @click="receivedData" :disabled="isReceiveExamData">确认已接收数据</wt-button>
            <wt-button primary @click="generateReport" :disabled="!isReceiveExamData">确认已编制报告</wt-button>
          </template>
          <!-- 待审核： -->
          <template v-if="nextStatus === 'REPORT_CHECKED'">
            <wt-button primary @click="taskStatusUpdate">确认已审核报告</wt-button>
          </template>
          <!-- 待归还： -->
          <template v-if="nextStatus === 'ORDER_BACK'">
            <wt-button primary @click="revertData" :disabled="isOrderBack">确认已归还</wt-button>
            <wt-button primary @click="recoveryData" :disabled="!isOrderBack">确认已回收</wt-button>
          </template>
          
        </template>

        <wt-button primary v-if="showDistributeButton" @click="distributeTask">分配任务</wt-button>
			<!-- </template> -->
			<!--分派操作-->
			<template v-if="isDistributeTask">
			  <wt-button primary @click="handleDistribute">新建任务单</wt-button>
			</template>
      
    </div>
  </div>
</template>
<script>
import {next} from 'cps/pt-sample-detail/com/status'
import { hasPermissions } from '@/utils/permissions'
export default {
  name: "status-operation",
  props: {
    nextStatus:String,
    type:String,
    order:Object,
    selectOrder:Array,
    hasTask:Boolean
  },
  data () {
    return {
      isDistributeTask:false,
      allSelect:false,
      testAgainForm:{}
    };
  },
  watch: {
    allSelect (val){
      this.$emit('selectAllSample', val)
    },
    type (val){ //切换状态后，操作状态复位
      if(val === 'trust-detail'){
        this.isDistributeTask = false
        this.allSelect = false
      }
    }
  },
  computed:{
    isReceiveExamData () {
      return this.order.isReceiveExamData
    },
    isOrderBack () {
      return this.order.isOrderBack
    },
    /*展示分派任务按钮。在审核和回传后，访客分派任务*/
    showDistributeButton (){
    const canDistribute = ['BILL_RECEIVE','BILL_ASSIGN','BILL_EXECUTE']
      return canDistribute.includes(this.status)
    },
    // HAS_PERM_SETTLEMENT () {
    //   const M_PERM = this.$auth.user() && this.$auth.user().permissions && hasPermissions(this.$auth.user().permissions, 'PERM_B_M')
    //   const SETTLEMENT_PERM = this.$auth.user() && this.$auth.user().permissions && hasPermissions(this.$auth.user().permissions, 'PERM_B_06')
    //   return M_PERM || SETTLEMENT_PERM
    // },
    // HAS_PERM_INVOICE () {
    //   const M_PERM = this.$auth.user() && this.$auth.user().permissions && hasPermissions(this.$auth.user().permissions, 'PERM_B_M')
    //   const SETTLEMENT_PERM = this.$auth.user() && this.$auth.user().permissions && hasPermissions(this.$auth.user().permissions, 'PERM_B_07')
    //   return M_PERM || SETTLEMENT_PERM
    // },
  },
  components: {
  },
  methods: {
    distributeTask (){
      this.isDistributeTask = true
      this.$emit('distributeTask')
    },
    handleOperation(path){
      this.$router.push(path)
    },
    receivedData () {
      let data = {
        orderStatusResult: 'RECEIVE_EXAM_DATA'
      }
      this.taskStatusUpdate(data)
    },
    generateReport () {
      let data = {
        orderStatusResult: 'REPORT_CREATED'
      }
      this.taskStatusUpdate(data)
    },
    revertData () {
      let data = {
        orderStatusResult: 'CONFIRM_ORDER_BACK'
      }
      this.taskStatusUpdate(data)
    },
    recoveryData () {
      let data = {
        orderStatusResult: 'CONFIRM_ORDER_RECOVERY'
      }
      this.taskStatusUpdate(data)
    },
    taskStatusUpdate(data){
      this.$loading()
      if (this.nextStatus === 'ORDER_EXAM') {
        this.testAgainForm.orderStatusResult = 'CONFIRM_EXAM_DATA'
      }

      let dataCurrent = {
        billId: this.order.billId,
        action: this.nextStatus,
        taskId:this.order.id,
        productionTaskTrigger:true,
        orderStatusDetail: {
          action: this.nextStatus,
          ...this.testAgainForm,
          ...data
        }
      }
      next.call(this, dataCurrent,true).then(res => {
        this.$emit('reload')
      }).catch(err => {
        this.$toast.error(err.message || err)
      }).finally(()=>{
        this.$loading.hide()
      })
    },
    getReceived (){
      this.$router.push({path:`/sample-status/${this.nextStatus}/${this.order.id}`, query:{isTask:true,billId:this.order.billId}})
    },
    // 改派任务
    handleReassign(){
      const {id,billId,serialNumber,labTester} = this.order
      this.$router.push(`/task-reassign/${id}/${billId}?serialNumber=${serialNumber}&labTesterName=${labTester.name || '--'}`)
    },
    // 分派任务
    handleDistribute () {
      if (!this.selectOrder.length) {
        this.$toast.error('请先勾选样品')
        return
      }
      this.$loading()
      let data = {
        billId: this.order.id,
        orderIds: this.selectOrder
      }
      this.$store.dispatch('assginPre', data).then(data => {
        this.$router.push(`/distribute-task/${data.billId}`)
      }).catch(err => {
        this.$toast.error(err || '操作失败')
      }).finally(() => {
        this.$loading.hide()
      })
    }
  }
};
</script>
<style module lang="less">
@import "~less/var.less";
.status-operation{
  height: 100%;
  display: flex;
  flex:1;
  justify-content: space-between;
	align-items: center;
	:global{
		.cube-checkbox{
			padding:0;
		}
    .cube-btn{
      width: auto;
    }
	}
  .tip{
      left:0;
      bottom: 46px;
    }
    .otherBtn{
      display: flex;
      flex-direction: column;
      line-height: 30px;
      span{
        border-bottom: 1px solid #DEDEDE;
        &:last-child{
          border-bottom: none;
        }
      }
    }
    >.btns{
      display: flex;
      text-align: right;
      :global{
        .cube-btn{
          padding: 8px 10px;
          margin-right: 10px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
    :global{
      .cube-tip{
        background: #fff;
        color:#333;
        box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.08);
        max-height: fit-content;
        .cube-tip-angle::before{
          border-color: transparent transparent #f0f0f0;
          // box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.08);
        }
      }
    }
  }
</style>
