<template>
  <pt-task-order-detail :id="id" :data="data" :bill="bill" @reload="getTaskDetail" class="pt-task-order-detail__withData"></pt-task-order-detail>
</template>

<script>
import PtTaskOrderDetail from './index'
export default {
  name: 'pt-task-order-detail__withData',
  components: {
    'pt-task-order-detail': PtTaskOrderDetail
  },
  props:{
    id:String
  },
  data (){
    return{
      data:{},
      bill:null,
      hasTask:false
    }
  },
  methods:{
    getTaskDetail (){
      this.$loading()
      this.$store.dispatch('getProductionTasksDetail', this.id).then(res => {
        // this.data = res
        // if(res.nextBillAction && res.futureTasks?.find(e => e.billActionData?.action === res.nextBillAction)){
        //   this.showDialog()
        // }
        this.$loading.hide()
        this.data = res.productionTask
        this.bill = res.bill
      }).catch(err => {
        this.$loading.hide()
        this.$toast.error(err || '获取任务单详情失败')
      })
    },
    showDialog(){
      this.hasTask = true
      this.$createDialog({
        type: 'alert',
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '我知道了',
          active: true
        },
        content: '该委托单已进入待处理状态，请前往电脑端进行相关操作',
      }).show()
    }
  },
  created (){
    this.getTaskDetail()
  }
}
</script>
