<template>
  <div :class="$style['content']">
    <div>
      <span>任务单编号：</span>
      <p>{{order.serialNumber || '--'}}</p>
    </div>
    <div>
      <span>实验人员：</span>
      <p>{{order.labTester && order.labTester.name ? order.labTester.name :'--'}}</p>
    </div>
    <div>
      <span>派单人员：</span>
      <p>{{order.creator || '--'}}</p>
    </div>
    <div>
      <span>派单日期：</span>
      <p>{{order.create_time || '--'}}</p>
    </div>
    <div>
      <span>委托单编号：</span>
      <p>{{bill && bill.billCode ? bill.billCode : '--'}}</p>
    </div>
    <div>
      <span>是否加急：</span>
      
      <p>
        <template v-if="!bill">
          --
        </template>
        <template v-else>
          <status-tag class="status-tag" background-color="#FF2D2D"  v-if="bill.urgent"></status-tag>
          <template v-else>否</template>
        </template>
      </p>
    </div>
    <div>
      <span>检测项目数：</span>
      <p>{{order.examItemCount}}</p>
    </div>
    <!-- <div>
      <span>任务进度：</span>
      <p></p>
    </div> -->
    <div>
      <span>状态：</span>
      <p :class="$style['mark']">{{order.status_text}}</p>
    </div>
    <div>
      <span>交付方式：</span>
      <p>{{order.delivery_type}}</p>
    </div>
    <div>
      <span>备注：</span>
      <p>{{order.comment}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "base-info",
  props: {
    order:{
      type:Object,
      default:() => {}
    },
    bill:{
      type:Object,
      default:() => {}
    }
  },
  computed:{
    INVOICE_PICTURE_attachments () {
      return this.order?.attachments?.filter(e => e.type === 'INVOICE_PICTURE')
    },
  },
  data () {
    return {
     
    };
  },
  watch: {
   
  },
  
  methods: {
    
  }
};
</script>
<style module lang="less">
@import "~less/var.less";
.content{
    padding: 15px;
    margin-top: 15px;
    background-color: #fff;
    text-align: left;
    > div{
      display: flex;
      line-height: 0.8rem;
      > span{
        width: 2.7rem;
        color: @label-color;
        text-align: left;
        white-space: nowrap;
      }
      >p{
        flex:1;
      }
      :global{
        .status-tag{
          line-height: 15px;
          width: fit-content;
        }
      }
      .mark{
        color: @main-color;
        margin-right: 3px;
      }
    }
  }
</style>
